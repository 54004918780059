import React, { FC } from 'react'
import { Layout, Heading, Select, Checkbox, File, Button, Input, TextArea } from '../components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { breakpoints } from '../helpers'

const Spacer = styled.div`
	padding: 2rem 0;

	${breakpoints.mobile} {
		padding: 1rem 0;
	}
`

const StyleGuidePageTemplate: FC = () => {
	return (
		<div style={{ paddingTop: '3rem', paddingBottom: '3rem' }}>
			<Grid>
				<Heading as='h1' variant='h1'>
					Headings
				</Heading>
				<Spacer />
				<Row>
					<Col xs>
						<Heading as='h1' variant='h1'>
							Heading 1
						</Heading>
						<Heading as='h2' variant='h2'>
							Heading 2
						</Heading>
						<Heading as='h3' variant='h3'>
							Heading 3
						</Heading>
						<Heading as='h4' variant='h4'>
							Heading 4
						</Heading>
					</Col>
				</Row>
				<Spacer />
				<Heading as='h1' variant='h1'>
					Buttons
				</Heading>
				<Spacer />
				<Button>Primary</Button>
				<br />
				<br />
				<Button variant='secondary'>Secondary</Button>
				<br />
				<br />
				<Button variant='tertiary'>Tertiary</Button>
				<br />
				<br />
				<Button variant='transparent'>Transparent</Button>
				<Spacer />
				<Heading as='h1' variant='h1'>
					Form elements
				</Heading>
				<Spacer />
				<Row>
					<Col xs={12}>
						<Input label='Test 1' id='test1' title='test' type='text' placeholder='This is a placeholder' />
						<Input
							label='Test 2'
							id='test2'
							title='test'
							type='text'
							placeholder='This is a placeholder'
							value='Delugia'
							status='successful'
						/>
						<Input
							label='Test 3'
							id='test3'
							title='test'
							type='text'
							placeholder='This is a placeholder'
							value=''
							status='error'
							message='Please enter field'
							helper='Your renewal will be sent straight to your intermediary for handling'
						/>
						<Input
							label='Policy Number*'
							id='test4'
							title='test 4'
							placeholder='000 0000 00'
							value=''
							status='error'
							message='Sorry we could not find a record of this policy number'
						/>
						<File
							name={'test-file'}
							label={`Upload your Id or Passport and proof of address`}
							helper={'E.g. utility bill, bank statement'}
							id={'file-test'}
						/>
						<Select
							label={'Country*'}
							id={'country'}
							options={['Australia', 'New Zealand']}
							placeholder='Select your country...'
						/>
						<Select
							label={'Country*'}
							id={'country'}
							options={['Australia', 'New Zealand']}
							placeholder='Select your country...'
							disabled
						/>
						<TextArea id={'textarea'} label={'Textarea'} placeholder={'Enter some text'} />
					</Col>
				</Row>
			</Grid>
			<Checkbox
				id={'test checkbox 1'}
				name={'test checkbox 1'}
				labelText={
					<>
						I, the insured, hereby confirm acceptance of the terms of renewal in relation to the above referenced
						policies.
						<br /> Please proceed to renewal.
					</>
				}
			/>
			<Checkbox
				id={'test checkbox 2'}
				name={'test checkbox 2'}
				labelText={
					<>
						I, the insured, hereby confirm acceptance of the terms of renewal in relation to the above referenced
						policies.
						<br /> Please proceed to renewal.
					</>
				}
				showMessage
				message={'To continue please click the checkbox'}
			/>
		</div>
	)
}

const StyleGuidePage: FC = () => {
	return (
		<Layout showRenewLinkOnMobile>
			<StyleGuidePageTemplate />
		</Layout>
	)
}

export default StyleGuidePage
export { StyleGuidePageTemplate }
